import { useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import { signupAPI } from "../services/apis/User";

import { ReactComponent as EWbg } from "../assets/ew-bg-contact.svg";
import { ReactComponent as UploadFile } from "../assets/UploadFile.svg";
import { ReactComponent as ConfirmIcon } from "../assets/confirm-icon.svg";

import { ReactComponent as BecomeTeleweler } from "../assets/BecomeTeleweler.svg";
// import { ReactComponent as XCircle } from "../assets/dashboard/XCircle.svg";

import Header from "../components/module/Landing/Header";
import Footer from "../components/module/Landing/Footer";
import Loader from "../components/common/Loader";
import TermsModal from "../components/common/TermsModal";
import PhoneField from "../components/PhoneField"

AWS.config.update({
  accessKeyId: "AKIA2O26TDY7DCNW5YX7",
  secretAccessKey: "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",
  region: "eu-north-1",
});

export default function UploadMakeMoney() {
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [user, setuser] = useState({
    full_name: "",
    phone_number: "",
    email_address: "",
    instagram_url: "",
    password: "",
    confirmPassword: "",
    older_18: false,
    video_link: "",
    is_owned: false,
    is_hot: false,
    is_agree: false,
  });
  const [openTermsModal, setopenTermsModal] = useState(false);

  async function signup() {
    if (
      user.full_name &&
      user.phone_number &&
      user.email_address &&
      user.password &&
      user.confirmPassword &&
      (user.video_link || user.instagram_url)
    ) {
      if (user.phone_number.toString().includes("+")) {

        if (user.password === user.confirmPassword) {
          if (user.is_agree) {
            await signupAPI(user)
              .then((res) => {
                toast.success("Successfully sign up.", {
                  transition: Slide,
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                navigate("/verification-code", {
                  state: {
                    phone_number: user.phone_number,
                  }
                }
                );
              })
              .catch((err) => {
                toast.error(err.response.data.errors[0], {
                  transition: Slide,
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          } else {
            toast.error(
              "Please make sure to agree with our terms and conditions.",
              {
                transition: Slide,
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        } else {
          toast.error("Password mismatch.", {
            transition: Slide,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error("Phone number should start with +CountryCode.", {
          transition: Slide,
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }



    } else {
      toast.error("Please make sure to fill all fields.", {
        transition: Slide,
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function generateRandomChars() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleFileChange = async (event) => {
    setShowLoader(true);

    const file = event.target.files[0];

    var extension = file.name.split(".").pop();
    let randomName = generateRandomChars() + "." + extension;

    // S3 Bucket Name
    const S3_BUCKET = "teleweevideos";

    // S3 Region
    const REGION = "eu-north-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIA2O26TDY7DCNW5YX7",
      secretAccessKey: "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",
      region: "eu-north-1",
    });
    const s3 = new AWS.S3();

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: randomName,
      Body: file,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
        setUploadPercentage(parseInt((evt.loaded * 100) / evt.total) + "%");
      })
      .promise();

    await upload
      .then((err, data) => {
        // Fille successfully uploaded
        // console.log(file);
        // console.log(data);
        // console.log(
        //   "https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName
        // );

        setuser((prevState) => ({
          ...prevState,
          video_link:
            "https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName,
        }));
        setShowLoader(false);
        setUploadPercentage(null);
      })
      .catch((err) => {
        setUploadPercentage(null);
        setShowLoader(false);
        alert("Something went wrong!!");
      });
  };

  return (
    <div>
      {showLoader ? (
        <div>
          <Loader percentage={uploadPercentage} />
        </div>
      ) : null}
      <Header />
      <div
        dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
        className="relative px-2 lg:px-0"
      >
        <EWbg className="absolute left-0 -top-40 lg:top-6 w-[60%] lg:w-auto -z-10" />

        <div className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto my-0 lg:my-10">
          <div className="flex flex-col space-y-5 mx-auto">
            <h1
              className={`text-palette-watermelon font-DMsansExtraBold text-4xl lg:text-5xl text-center ${currentLanguageCode === "ar" ? "lg:text-right" : "lg:text-left"
                }  mt-4 lg:mt-0`}
            >
              {t("BecomingaTeleweler")}
            </h1>
            <p
              className={`mt-2 mb-5 text-base lg:text-lg max-w-lg font-DMsansRegular text-center ${currentLanguageCode === "ar" ? "lg:text-right" : "lg:text-left"
                }`}
            >
              {t("BecomingaTelewelerDesc")}
            </p>
            <BecomeTeleweler className="order-first lg:order-last h-[299.9px] w-[324px] lg:h-[428.37px] lg:w-[462.79px] mx-auto lg:mx-0" />
          </div>

          <div className="bg-white shadow-none lg:shadow w-full p-5 rounded-2xl">
            <div>
              <h3 className="font-DMsansBold text-lg mb-4">
                {t("PersonalInformation")}
              </h3>
              <div className="space-y-3">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                  <div>
                    <label className="block font-DMsansRegular text-md mb-1">
                      {t("FullName")}
                    </label>
                    <input
                      className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                      placeholder={t("Enter-your-full-name")}
                      type="text"
                      value={user.full_name}
                      onChange={(e) =>
                        setuser({ ...user, full_name: e.target.value })
                      }
                    />
                  </div>
                  <div >
                    <label
                      className="block font-DMsansRegular text-md mb-1">
                      {t("PhoneNumber")}

                    </label>
                    <PhoneField
                      className={"border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"}
                      value={user.phone_number}
                      onChange={(e) => {
                        setuser({ ...user, phone_number: e })
                      }
                      }
                      placeholder={t("Enter-your-phone-number")}
                    />

                  </div>
                </div>

                <div>
                  <label className="block font-DMsansRegular text-md mb-1">
                    {t("Email")}
                  </label>
                  <input
                    className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                    placeholder={t("EnterYourEmail")}
                    type="email"
                    value={user.email_address}
                    onChange={(e) =>
                      setuser({ ...user, email_address: e.target.value })
                    }
                  />
                </div>







                <div>
                  <label className="block font-DMsansRegular text-md mb-1">
                    {t("InstagramURL")}
                  </label>
                  <input
                    autoComplete="off"
                    name="instagram-url"
                    id="instagram-url"
                    className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                    placeholder={t("InstagramURL")}
                    type="text"
                    inputMode="url"
                    value={user.instagram_url}
                    onChange={(e) =>
                      setuser({ ...user, instagram_url: e.target.value })
                    }
                  />
                </div>
                <div className=" font-DMsansRegular text-md mb-1">


                  {t("AllowInstagram")}
                </div>


                <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                  <div>
                    <label className="block font-DMsansRegular text-md mb-1">
                      {t("Password")}
                    </label>
                    <input
                      className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                      placeholder="**************"
                      type="password"
                      value={user.password}
                      onChange={(e) =>
                        setuser({ ...user, password: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <label className="block font-DMsansRegular text-md mb-1">
                      {t("ConfirmPassword")}
                    </label>
                    <input
                      className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                      placeholder="**************"
                      type="password"
                      value={user.confirmPassword}
                      onChange={(e) =>
                        setuser({ ...user, confirmPassword: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-DMsansBold text-lg my-4">
                {t("VideoInformation")}
              </h3>
              <div className="space-y-4">
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border border border-gray-300 px-6 py-10">
                    <div className="text-center">
                      {user.video_link ? (
                        <div>
                          {/* <XCircle
                            className="h-5 w-5 text-red-700 cursor-pointer"
                            onClick={() => {
                              setuser({ ...user, video_link: "" });
                            }}
                          /> */}
                          <video
                            className="w-[300px] h-[300px]"
                            src={user.video_link}
                            autoPlay={false}
                            controls
                          />
                        </div>
                      ) : (



                        !user.instagram_url.includes("instagram.com/") ?
                          <div>
                            <UploadFile
                              className="mx-auto h-14 w-14"
                              aria-hidden="true"
                            />

                            <div className="mt-4 flex text-base leading-6 text-[#AAAAAA]">
                              <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-DMsansSemiBold text-palette-watermelon"
                              >
                                <span className="font-DMsansRegular">
                                  {t("UploadAvideo")}
                                </span>
                                <input
                                  onChange={(e) => {
                                    handleFileChange(e);
                                  }}
                                  id="file-upload"
                                  name="file-upload"
                                  type="file"
                                  className="sr-only"
                                  accept=".mp4,.mov"
                                />
                              </label>
                              <p className="pl-1">{t("or-drag-and-drop")}</p>
                            </div>
                            <p className="text-sm leading-5 text-[#AAAAAA] font-DMsansRegular">
                              MP4, MOV
                            </p>
                          </div>
                          :
                          <div>
                            {/*  */}
                            <ConfirmIcon
                              className="mx-auto h-14 w-14"
                              aria-hidden="true"
                            />
                            <p className="text-sm text-black leading-5 text-[#AAAAAA] font-DMsansRegular my-2">
                              {t("telewee-will-download-videos")}
                            </p>
                          </div>

                      )}


                    </div>
                  </div>
                </div>

                <div>
                  <label className="block font-DMsansRegular text-md mb-1">
                    {t("VideoDescription")}
                  </label>
                  <textarea
                    rows={5}
                    className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                    placeholder={t(
                      "Write-a-few-sentences-about-the-purpose-of-the-video"
                    )}
                    type="text"
                    value={user.description}
                    onChange={(e) =>
                      setuser({ ...user, description: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div>
              <fieldset>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="is_owned"
                        name="is_owned"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                        value={user.is_owned}
                        onChange={(e) =>
                          setuser({ ...user, is_owned: e.target.checked })
                        }
                      />
                    </div>
                    <div className="text-base leading-6">
                      <label className="font-DMsansBold text-gray-900">
                        {t("I-own-the-video")}
                      </label>
                      <p className="text-[#AAAAAA] font-DMsansRegular">
                        {t("I-own-the-video-desc")}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="is_hot"
                        name="is_hot"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                        value={user.is_hot}
                        onChange={(e) =>
                          setuser({ ...user, is_hot: e.target.checked })
                        }
                      />
                    </div>
                    <div className="text-base leading-6">
                      <label className="font-DMsansBold text-gray-900">
                        {t("This-is-a-hot-topic")}
                      </label>
                      <p className="text-[#AAAAAA] font-DMsansRegular">
                        {t("This-is-a-hot-topic-desc")}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="older_18"
                        name="older_18"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                        value={user.older_18}
                        onChange={(e) =>
                          setuser({ ...user, older_18: e.target.checked })
                        }
                      />
                    </div>
                    <div className="text-base leading-6">
                      <label className="font-DMsansBold text-gray-900">
                        {t("18-or-older")}
                      </label>
                      <p className="text-[#AAAAAA] font-DMsansRegular">
                        {t("18-or-older-desc")}
                      </p>
                    </div>
                  </div>

                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="is_agree"
                        name="is_agree"
                        type="checkbox"
                        value={user.is_agree}
                        onChange={(e) =>
                          setuser({ ...user, is_agree: e.target.checked })
                        }
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                      />
                    </div>
                    <div onClick={((e) => {
                      navigate("/terms-and-conditions")
                    })} className="text-sm leading-6 font-DMsansRegular">
                      <p className="text-[#344054]">
                        {t("I-agree-with-the")}{" "}
                        <span className="underline">
                          {t("terms-and-conditions")}
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <div className="flex mt-14 mb-5 space-x-2 lg:space-x-5 rtl:space-x-reverse">
              <button className="grow border rounded-lg p-3 font-DMsansMedium">
                {t("Cancel")}
              </button>
              <button
                onClick={() => {
                  setopenTermsModal(true);
                }}
                className="grow bg-palette-watermelon rounded-lg text-white font-DMsansMedium"
              >
                {t("Submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <TermsModal
        open={openTermsModal}
        setOpen={setopenTermsModal}
        SubmitFunc={(e) => {
          signup();
          // handleUpload(e);
        }}
      />
      <Footer />
      <ToastContainer />
    </div>
  );
}
