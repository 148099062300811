import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AWS from "aws-sdk";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

import { addVideoAPI } from "../services/apis/User";

import { ReactComponent as UploadFile } from "../assets/UploadFile.svg";
import Loader from "../components/common/Loader";
import Layout from "../components/common/Layout";

AWS.config.update({
  accessKeyId: "AKIA2O26TDY7DCNW5YX7",
  secretAccessKey: "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",
  region: "eu-north-1",
});

export default function Dashboard() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(null);
  const [user, setuser] = useState({
    user: userData._id,
    video_link: "",
    is_owned: false,
  });

  async function add_video() {
    if (user.video_link) {
      await addVideoAPI(user)
        .then((res) => {
          toast.success("Successfully added video.", {
            transition: Slide,
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Please make sure to fill all fields.", {
        transition: Slide,
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function generateRandomChars() {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < 9; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const handleFileChange = async (event) => {
    setShowLoader(true);

    const file = event.target.files[0];

    var extension = file.name.split(".").pop();
    let randomName = generateRandomChars() + "." + extension;

    // S3 Bucket Name
    const S3_BUCKET = "teleweevideos";

    // S3 Region
    const REGION = "eu-north-1";

    // S3 Credentials
    AWS.config.update({
      accessKeyId: "AKIA2O26TDY7DCNW5YX7",
      secretAccessKey: "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",
      region: "eu-north-1",
    });
    const s3 = new AWS.S3();

    // Files Parameters

    const params = {
      Bucket: S3_BUCKET,
      Key: randomName,
      Body: file,
    };

    // Uploading file to s3

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
        setUploadPercentage(parseInt((evt.loaded * 100) / evt.total) + "%");
      })
      .promise();

    await upload
      .then((err, data) => {
        // Fille successfully uploaded
        // console.log(file);
        // console.log(data);
        // console.log(
        //   "https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName
        // );

        setuser((prevState) => ({
          ...prevState,
          video_link:
            "https://teleweevideos.s3.eu-north-1.amazonaws.com/" + randomName,
        }));
        setShowLoader(false);
        setUploadPercentage(null);
      })
      .catch((err) => {
        setUploadPercentage(null);
        setShowLoader(false);
        alert("Something went wrong!!");
      });
  };

  return (
    <Layout>
      {showLoader ? (
        <div>
          <Loader percentage={uploadPercentage} />
        </div>
      ) : null}
      <div dir="rtl" className="relative px-2 lg:px-0 h-screen">
        <div className="flex justify-center max-w-4xl mx-auto my-0 lg:my-10 z-50">
          <div className="bg-white shadow-none lg:shadow w-full p-5 rounded-2xl">
            <div>
              <h3 className="font-DMsansBold text-lg my-4">
                {t("VideoInformation")}
              </h3>
              <div className="space-y-4">
                <div className="col-span-full">
                  <div className="mt-2 flex justify-center rounded-lg border border border-gray-300 px-6 py-10">
                    <div className="text-center">
                      {user.video_link ? (
                        <div>
                          <video
                            className="w-[300px] h-[300px]"
                            src={user.video_link}
                            autoPlay={false}
                            controls
                          />
                        </div>
                      ) : (
                        <UploadFile
                          className="mx-auto h-14 w-14"
                          aria-hidden="true"
                        />
                      )}

                      <div className="mt-4 flex text-base leading-6 text-[#AAAAAA]">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-DMsansSemiBold text-palette-watermelon"
                        >
                          <span className="font-DMsansRegular">
                            {t("UploadAvideo")}
                          </span>
                          <input
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            accept=".mp4,.mov"
                          />
                        </label>
                        <p className="pl-1">{t("or-drag-and-drop")}</p>
                      </div>
                      <p className="text-sm leading-5 text-[#AAAAAA] font-DMsansRegular">
                        MP4, MOV
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block font-DMsansRegular text-md mb-1">
                    {t("VideoDescription")}
                  </label>
                  <textarea
                    rows={5}
                    className="border border-gray-300 rounded-lg p-2 w-full font-DMsansRegular shadow-sm outline-none"
                    placeholder={t(
                      "Write-a-few-sentences-about-the-purpose-of-the-video"
                    )}
                    type="text"
                    value={user.description}
                    onChange={(e) =>
                      setuser({ ...user, description: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div>
              <fieldset>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="is_owned"
                        name="is_owned"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                        value={user.is_owned}
                        onChange={(e) =>
                          setuser({ ...user, is_owned: e.target.checked })
                        }
                      />
                    </div>
                    <div className="text-base leading-6">
                      <label className="font-DMsansBold text-gray-900">
                        {t("I-own-the-video")}
                      </label>
                      <p className="text-[#AAAAAA] font-DMsansRegular">
                        {t("I-own-the-video-desc")}
                      </p>
                    </div>
                  </div>
                  {/* <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="is_hot"
                        name="is_hot"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-100 text-palette-watermelon"
                        value={user.is_hot}
                        onChange={(e) =>
                          setuser({ ...user, is_hot: e.target.checked })
                        }
                      />
                    </div>
                    <div className="text-base leading-6">
                      <label className="font-DMsansBold text-gray-900">
                        This is a hot topic
                      </label>
                      <p className="text-[#AAAAAA] font-DMsansRegular">
                        Marking this means that this topic is hot or trending or
                        urgent video.
                      </p>
                    </div>
                  </div> */}
                </div>
              </fieldset>
            </div>

            <div className="flex mt-14 mb-5 space-x-2 lg:space-x-5 rtl:space-x-reverse">
              <button className="grow border rounded-lg p-3 font-DMsansMedium">
                {t("Cancel")}
              </button>
              <button
                onClick={() => add_video()}
                className="grow bg-palette-watermelon rounded-lg text-white font-DMsansMedium"
              >
                {t("submit-new-video")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Layout>
  );
}
