import Layout from "../components/common/Layout";
import { ReactComponent as Card } from "../assets/dashboard/Card.svg";
// import { ReactComponent as UploadedVideos } from "../assets/dashboard/UploadedVideos.svg";
// import { ReactComponent as NumberOfViews } from "../assets/dashboard/NumberOfViews.svg";
// import { ReactComponent as RejectedVideos } from "../assets/dashboard/RejectedVideos.svg";
import { ReactComponent as AddCircle } from "../assets/dashboard/AddCircle.svg";
import { ReactComponent as ArrowDownCircle } from "../assets/dashboard/ArrowDownCircle.svg";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { dashboardAPI, editUserAPI } from "../services/apis/User";
import { truncate_content } from "../services/common";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import TermsModal from "../components/common/TermsModal";

export default function Dashboard() {
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const { t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("user"));
  const [wallet, setwallet] = useState(0);
  const [views, setViews] = useState(0);

  // const [userInfo, setuserInfo] = useState({});
  const [videos, setvideos] = useState([]);

  const [showTerms, setShowTerms] = useState(false);




  async function get_dashboard_data() {
    await dashboardAPI(user._id)
      .then((res) => {
        setwallet(res.userEarning);

        // used to show the modal for people didnt accept terms
        setShowTerms(!res.userInfo.accepted_condition_terms)


        setvideos(res.videos);
        let balance = 0;
        res.videos.map((item) => {
          balance += item.fb_total_video_views ? item.fb_total_video_views : 0;
          balance += item.b2b_fb_total_video_views ? item.b2b_fb_total_video_views : 0;
        });

        setViews(balance);
      })
      .catch((err) => console.log(err));
  }

  async function acceptTerms() {
    await editUserAPI({
      user: user._id,
      "accepted_condition_terms": true
    })
      .then((res) => {
        setShowTerms(false)
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    get_dashboard_data();
  }, []);

  return (
    <Layout>
      <div className="h-full lg:h-screen">
        <div
          dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
          className=" max-w-7xl mx-auto p-4 pt-10"
        >
          {/* <div className="relative bg-white rounded-xl w-[390px] h-[140px] lg:w-[540px] lg:h-[229px]">
            <Card className="w-[390px] h-[140px] lg:w-[540px] lg:h-[229px]" />
            <div className="absolute inset-0 flex justify-between items-center">
              <div className="mx-7 lg:mx-10 text-center text-palette-watermelon">
                <h3 className="text-base lg:text-2xl mb-2 font-semibold">
                  {t("CurrentBalance")}:
                </h3>
                <h3 className="text-4xl font-bold">${wallet.toFixed(3)}</h3>
              </div>

              <div className="flex justify-center items-center mx-7 w-28 lg:w-auto">
                <h3 className="text-3xl text-white font-semibold">
                  {t("MyWallet")}
                </h3>
              </div>
            </div>
          </div> */}

          {/* <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3 rounded">
  <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
    <dt class="text-sm font-medium leading-6 text-gray-500"> {t("MyWallet")}</dt>
    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-palette-watermelon">${wallet.toFixed(3)}</dd>
  </div>
  <div class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
    <dt class="text-sm font-medium leading-6 text-gray-500">Total Views</dt>
    <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">123232</dd>
  </div>
</dl> */}

          {user && user.full_name ? (
            <h1
              className={`${currentLanguageCode === "ar" ? "text-right" : "text-left"
                } text-lg mb-7`}
            >
              {" "}
              {t("Welcome")},{" "}
              <span className="font-semibold">{user.full_name}</span>!{" "}
            </h1>
          ) : null}

          <div className="flex flex-col gap-x-0 gap-y-5 lg:gap-y-0 lg:gap-x-5  lg:flex-row ">
            <div className="bg-white shadow rounded-2xl py-4 px-8 lg:w-72">
              <p class="text-sm font-medium leading-6 text-gray-800">
                {t("MyWallet")}
              </p>
              <p class="mt-2 flex items-baseline gap-x-2">
                <span class="text-4xl font-semibold tracking-tight text-palette-watermelon">
                  ${wallet.toFixed(3)}
                </span>
              </p>
            </div>
            <div className="bg-white shadow rounded-2xl py-4 px-8 lg:w-72">
              <p class="text-sm font-medium leading-6 text-gray-800">
                {t("TotaViews")}
              </p>
              <p class="mt-2 flex items-baseline gap-x-2">
                <span class="text-4xl font-semibold tracking-tight text-palette-watermelon">
                  {views.toLocaleString()}
                </span>
              </p>
            </div>
          </div>

          {/* <div className="grid grid-cols-2 gap-4 lg:gap-10 my-auto w-full">
            <div className="bg-white flex rounded-xl">
              <div className="flex px-2 lg:px-5 py-4 ">
                <UploadedVideos />
              </div>
              <div className="my-auto">
                <h1 className="font-bold text-md lg:text-xl">10</h1>
                <h1 className="text-gray-500 text-sm lg:text-lg">
                  Uploaded videos{" "}
                </h1>
              </div>
            </div>

            <div className="bg-white flex rounded-xl">
              <div className="flex px-2 lg:px-5 py-4 ">
                <NumberOfViews />
              </div>
              <div className="my-auto">
                <h1 className="font-bold text-md lg:text-xl">100K</h1>
                <h1 className="text-gray-500 text-sm lg:text-lg">
                  Number of views
                </h1>
              </div>
            </div>

            <div className="bg-white flex rounded-xl">
              <div className="flex px-2 lg:px-5 py-4 ">
                <RejectedVideos />
              </div>
              <div className="my-auto">
                <h1 className="font-bold text-md lg:text-xl">10</h1>
                <h1 className="text-gray-500 text-sm lg:text-lg">
                  Rejected videos
                </h1>
              </div>
            </div>
          </div> */}
        </div>
        <div className="max-w-7xl mx-auto p-4">
          <div
            dir={currentLanguageCode === "ar" ? "rtl" : "ltr"}
            className="flex justify-between mt-5"
          >
            <h3 className="font-bold text-xl">{t("VideosHistory")}</h3>
            <button
              onClick={() => navigate("/add-video")}
              className="flex bg-palette-watermelon text-white rounded-lg px-4 py-2"
            >
              <AddCircle className="mr-2" />
              {t("AddVideo")}
            </button>
          </div>

          <Table data={videos} />
        </div>
      </div>
    </Layout>
  );


  function Table({ data }) {
    const [expandedRow, setExpandedRow] = useState(null);
    const { t } = useTranslation();

    const handleRowClick = (index) => {
      setExpandedRow((prevExpandedRow) =>
        prevExpandedRow === index ? null : index
      );
    };

    return (
      <>
        <div className="mt-7 lg:block hidden ">
          <table className="w-full rounded-t-2xl">
            <thead className="">
              <tr>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Status")}
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Published-date")}
                </th>

                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Monetization-amount")}$
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Total-Views")}
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE] rounded-tr-xl">
                  {t("Video-Link")}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr
                  key={row.videoId}
                  className="border-b border-gray-300 bg-white shadow-lg"
                >
                  <td className="py-3 px-4">
                    <span
                      className={`inline-block font-semibold px-3 py-1 rounded-full ${row.status === "approved" || row.status == "editing"
                          ? "bg-[#FFF9EB] text-[#F9AB01] border border-[#F9AB01]"
                          : row.status === "pending"
                            ? "bg-[#FFFFFF] text-[#636362] border border-[#636363]"
                            : row.status === "published"
                              ? "bg-[#EBFCF8] text-[#6DC8B7] border border-[#6DC8B7]"
                              : "bg-[#F7ECQA] text-[#ED674B] border border-[#ED674B]"
                        }`}
                    >
                      {t(row.status)}
                    </span>
                    {row.status == "rejected" ? (
                      <span
                        className="mx-2 text-xs text-gray-700 underline flex  my-2"
                        onClick={(e) => alert(row.rejection_reason)}
                      >
                        See Why?
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="h-4 w-4 mx-1"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </span>
                    ) : null}
                  </td>
                  <td className="py-3 px-4">
                    {moment(row.created_on).format("DD/MM/YYYY")}
                  </td>
                  {/* <td className="py-3 px-4">{row.rejectionReason}</td> */}
                  <td className="py-3 px-4">
                    {parseFloat(row.user_earning).toFixed(4)}$
                  </td>

                  <td className="py-3 px-4">{parseFloat(row.fb_total_video_views) + parseFloat(row.b2b_fb_total_video_views)}</td>

                  <td className="py-3 px-4 underline">
                    <a
                      href={row.video_link}
                      target="_blank"
                      rel="noopener noreferrer "
                    >
                      {t("download")}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="my-7 lg:hidden block overflow-x-auto">
          <table className="w-full rounded-t-2xl">
            <thead>
              <tr>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE] rounded-tl-xl">
                  {t("Status")}
                </th>

                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Published-date")}
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Monetization-amount")}
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE]">
                  {t("Total-Views")}
                </th>
                <th className="py-3 px-4 font-medium border-b border-gray-300 text-left bg-[#EDEDEE] rounded-tr-xl">
                  {t("Video-Link")}
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <React.Fragment key={row._id}>
                  <tr
                    className={`${expandedRow === index ? "" : "border-b border-gray-300"
                      } bg-white shadow-lg`}
                    onClick={() => handleRowClick(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="py-3 px-4 text-center">
                      <span
                        className={`inline-block font-semibold px-1 py-1 rounded-full ${row.status === "approved" || row.status == "editing"
                            ? "bg-[#FFF9EB] text-[#F9AB01] border border-[#F9AB01]"
                            : row.status === "pending"
                              ? "bg-[#FFFFFF] text-[#636362] border border-[#636363]"
                              : row.status === "published"
                                ? "bg-[#EBFCF8] text-[#6DC8B7] border border-[#6DC8B7]"
                                : "bg-[#F7ECEA] text-[#ED674B] border border-[#ED674B]"
                          }`}
                      >
                        {/* {row.status} */}
                        {t(row.status)}
                      </span>

                      {row.status == "rejected" ? (
                        <span
                          className="mx-2 text-sm text-gray-700 underline flex  my-2"
                          onClick={(e) => alert(row.rejection_reason)}
                        >
                          See Why?
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="h-8 w-8 mx-1"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        </span>
                      ) : null}
                    </td>

                    {/* <td className="py-3 px-4 text-center">
                      {row.status=="rejected"? row.rejection_reason:"N/A"}
                    </td> */}
                    <td className="py-3 px-4 text-center">
                      {moment(row.created_on).format("DD/MM/YYYY")}
                    </td>
                    <td className="py-3 px-4 text-center">
                      {parseFloat(row.user_earning).toFixed(2)}$
                    </td>
                    <td className="py-3 px-4 text-center">
                      {parseFloat(row.fb_total_video_views) + parseFloat(row.b2b_fb_total_video_views)}
                    </td>

                    <td className="py-3 px-4 underline">
                      <a
                        href={row.video_link}
                        target="_blank"
                        rel="noopener noreferrer "
                      >
                        {t("download")}
                      </a>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>

          <TermsModal
            open={showTerms}
            setOpen={setShowTerms}
            SubmitFunc={(e) => {
              acceptTerms();

              // signup();

              // handleUpload(e);
            }}
          />
        </div>
      </>
    );
  }
}


